<template>
  <div class="page-content page-container">
    <div class="padding">
      <div class="row container d-flex justify-content-center">
        <div class="col-md-12">
          <div class="card px-3">
            <div class="card-body">
              <h4 class="card-title">Awesome Todo list</h4>
              <form @submit="addNewItem">
                <div class="add-items d-flex">
                  <input
                    type="text"
                    v-model="newitem.description"
                    class="form-control todo-list-input text-capitalize"
                    placeholder="What do you need to do today?"
                    required
                  />
                  <button
                    type="submit"
                    class="add btn btn-primary font-weight-bold todo-list-add-btn"
                  >
                    Add
                  </button>
                </div>
              </form>
              <div class="list-wrapper">
                <transition-group
                  name="slide-up"
                  tag="ul"
                  v-if="newItems"
                  class="d-flex flex-column-reverse todo-list"
                >
                  <li v-for="item in newItems" :key="item.id">
                    <div
                      v-if="!Boolean(Number(item.completed))"
                      class="form-check"
                    >
                      <label class="form-label  text-capitalize">
                        <input
                          class="checkbox"
                          @change="updItem(item)"
                          type="checkbox"/>
                        {{ item.description }}
                        <i class="input-helper"></i
                      ></label>
                    </div>
                    <div
                      v-if="Boolean(Number(item.completed))"
                      class="form-check complet"
                    >
                      <label class="form-label completdiv">
                        <input
                          class="checkbox"
                          @change="updItem(item)"
                          type="checkbox"
                          checked/>
                        {{ item.description }}
                        <i class="input-helper"></i
                      ></label>
                    </div>
                    <i
                      @click="remItem(item.id)"
                      class="remove mdi mdi-close-circle-outline"
                    ></i>
                  </li>
                </transition-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Items",
  data() {
    return {
      respons: {},
      items: [],
      newitem: {
        id: "",
        name: "new todo",
        description: "",
        completed: 0
      }
    };
  },
  methods: {
    ...mapActions([
      "getTest",
      "getItems",
      "addItem",
      "removeItem",
      "updateItem"
    ]),
    addNewItem(e) {
      e.preventDefault();
      this.addItem(this.newitem);
      this.newitem.name = "";
      this.newitem.description = "";
    },
    remItem(i) {
      let req = { id: i };
      this.removeItem(req);
    },
    updItem(i) {
      let req = { id: i.id, completed: i.completed };
      this.updateItem(req);
    }
  },
  computed: mapGetters(["newResponse", "newItems"]),
  created() {
    this.getItems();
  }
};
</script>

<style scoped>
.page-content {
  width: 100%;
}
.flex {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

@media (max-width: 991.98px) {
  .padding {
    padding: 1.5rem;
  }
}

@media (max-width: 767.98px) {
  .padding {
    padding: 1rem;
  }
}

.padding {
  padding: 5rem;
}

.card {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #d2d2dc;
  border-radius: 0;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.card .card-body {
  padding: 1.25rem 1.75rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card .card-title {
  color: #000000;
  margin-bottom: 0.625rem;
  text-transform: capitalize;
  font-size: 0.875rem;
  font-weight: 500;
}

.add-items {
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.d-flex {
  display: flex !important;
}

.add-items input[type="text"] {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  background: transparent;
}

.form-control {
  border: 1px solid #f3f3f3;
  font-weight: 400;
  font-size: 0.875rem;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.875rem 1.375rem;
  font-size: 1rem;
  line-height: 1;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.add-items .btn {
  margin-left: 0.5rem;
}

.btn {
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 400;
  padding: 0.7rem 1.5rem;
  border-radius: 0.1275rem;
}

.list-wrapper {
  height: 100%;
  max-height: 100%;
}

.add-items {
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.add-items input[type="text"] {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  background: transparent;
}

.add-items .btn,
.add-items .fc button,
.fc .add-items button,
.add-items .ajax-upload-dragdrop .ajax-file-upload,
.ajax-upload-dragdrop .add-items .ajax-file-upload,
.add-items .swal2-modal .swal2-buttonswrapper .swal2-styled,
.swal2-modal .swal2-buttonswrapper .add-items .swal2-styled,
.add-items .wizard > .actions a,
.wizard > .actions .add-items a {
  margin-left: 0.5rem;
}

.rtl .add-items .btn,
.rtl .add-items .fc button,
.fc .rtl .add-items button,
.rtl .add-items .ajax-upload-dragdrop .ajax-file-upload,
.ajax-upload-dragdrop .rtl .add-items .ajax-file-upload,
.rtl .add-items .swal2-modal .swal2-buttonswrapper .swal2-styled,
.swal2-modal .swal2-buttonswrapper .rtl .add-items .swal2-styled,
.rtl .add-items .wizard > .actions a,
.wizard > .actions .rtl .add-items a {
  margin-left: auto;
  margin-right: 0.5rem;
}

.list-wrapper {
  height: 100%;
  max-height: 100%;
}

.list-wrapper ul {
  padding: 0;
  text-align: left;
  list-style: none;
  margin-bottom: 0;
}

.list-wrapper ul li {
  font-size: 0.9375rem;
  padding: 0.4rem 0;
  border-bottom: 1px solid #f3f3f3;
}

.list-wrapper ul li:first-child {
  border-bottom: none;
}

.list-wrapper ul li .form-check {
  max-width: 90%;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.list-wrapper ul li .form-check label:hover {
  cursor: pointer;
}

.list-wrapper input[type="checkbox"] {
  margin-right: 15px;
}

.list-wrapper .remove {
  cursor: pointer;
  font-size: 1.438rem;
  font-weight: 600;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 20px;
  text-align: center;
}

.completdiv {
  background-color: rgb(185, 255, 195);
}
.complet {
  text-decoration: darkgray;
  text-transform: uppercase;
  text-decoration-color: #000;
  color: rgb(110, 110, 110);
}

.list-wrapper ul li .form-check {
  max-width: 90%;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.list-wrapper ul li .form-check,
.list-wrapper ul li .form-check .form-check-label,
.email-wrapper .mail-sidebar .menu-bar .profile-list-item a .user .u-name,
.email-wrapper
  .mail-sidebar
  .menu-bar
  .profile-list-item
  a
  .user
  .u-designation,
.email-wrapper .mail-list-container .mail-list .content .sender-name,
.email-wrapper .message-body .attachments-sections ul li .details p.file-name,
.settings-panel .chat-list .list .info p {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}

.form-check {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 0;
}

.list-wrapper ul li .form-check,
.list-wrapper ul li .form-check .form-check-label,
.email-wrapper .mail-sidebar .menu-bar .profile-list-item a .user .u-name,
.email-wrapper
  .mail-sidebar
  .menu-bar
  .profile-list-item
  a
  .user
  .u-designation,
.email-wrapper .mail-list-container .mail-list .content .sender-name,
.email-wrapper .message-body .attachments-sections ul li .details p.file-name,
.settings-panel .chat-list .list .info p {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}

.form-check .form-check-label {
  min-height: 18px;
  display: block;
  margin-left: 1.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-check-label {
  margin-bottom: 0;
}

.list-wrapper input[type="checkbox"] {
  margin-right: 15px;
}

.form-check .form-check-label input {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

.list-wrapper ul li .form-check,
.list-wrapper ul li .form-check .form-check-label,
.email-wrapper .mail-sidebar .menu-bar .profile-list-item a .user .u-name,
.email-wrapper
  .mail-sidebar
  .menu-bar
  .profile-list-item
  a
  .user
  .u-designation,
.email-wrapper .mail-list-container .mail-list .content .sender-name,
.email-wrapper .message-body .attachments-sections ul li .details p.file-name,
.settings-panel .chat-list .list .info p {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}

.form-check .form-check-label input[type="checkbox"] + .input-helper:before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: solid #405189;
  border-width: 2px;
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}

.form-check .form-check-label input[type="checkbox"] + .input-helper:before,
.form-check .form-check-label input[type="checkbox"] + .input-helper:after {
  position: absolute;
  top: 0;
  left: 0;
}

.form-check .form-check-label input[type="checkbox"] + .input-helper:after {
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  font-family: Material Design Icons;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  content: "\F12C";
  font-size: 0.9375rem;
  font-weight: bold;
  color: #ffffff;
}

.form-check .form-check-label input[type="checkbox"] + .input-helper:before,
.form-check .form-check-label input[type="checkbox"] + .input-helper:after {
  position: absolute;
  top: 0;
  left: 0;
}

.form-check
  .form-check-label
  input[type="checkbox"]:checked
  + .input-helper:before {
  background: #405189;
  border-width: 0;
}

.form-check .form-check-label input[type="checkbox"] + .input-helper:before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: solid #405189;
  border-width: 2px;
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}

.form-check .form-check-label input[type="checkbox"] + .input-helper:after {
  font-family: FontAwesome;
  content: "\f095";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  color: #fff;
}

.text-primary,
.list-wrapper .completed .remove {
  color: #405189 !important;
}

.list-wrapper .remove {
  cursor: pointer;
  font-size: 1.438rem;
  font-weight: 600;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 20px;
  text-align: center;
}

.ml-auto,
.list-wrapper .remove,
.mx-auto {
  margin-left: auto !important;
}

.mdi-close-circle-outline:before {
  content: "\F15A";
}

.list-wrapper ul li {
  font-size: 0.9375rem;
  padding: 0.4rem 0;
  border-bottom: 1px solid #f3f3f3;
}

.mdi:before {
  font-family: FontAwesome;
  content: "\f00d";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-size: 0.756em;
  color: #405189;
}

.list-wrapper ul {
  padding: 0;
  text-align: left;
  list-style: none;
  margin-bottom: 0;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.d-flex,
.loader-demo-box,
.distribution-chart-legend .distribution-chart,
.distribution-chart-legend .distribution-chart .item,
.list-wrapper ul li,
.email-wrapper .mail-sidebar .menu-bar .profile-list-item a,
.email-wrapper .mail-sidebar .menu-bar .profile-list-item a .user,
.email-wrapper .mail-list-container .mail-list .details,
.email-wrapper .message-body .attachments-sections ul li .thumb,
.email-wrapper .message-body .attachments-sections ul li .details .buttons,
.lightGallery .image-tile .demo-gallery-poster,
.swal2-modal,
.navbar .navbar-menu-wrapper .navbar-nav,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .navbar-dropdown
  .dropdown-item {
  display: flex !important;
}
/** slide-up transition */
.slide-up-enter {
  transform: translateY(100px);
  opacity: 0;
}
.slide-up-enter-active {
  transition: all 200ms ease;
}
</style>
